.AddNewActivity-main-div {
  .new-activity-text {
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    color: #000000;
  }
  .first-button {
    font-weight: 800;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.5);
    height: 48px;
    width: 100px;
  }
  .second-button {
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    background: #607f96;
    border-radius: 8px;
    height: 48px;
    width: 100px;
  }
  .edit-details-card {
    padding: 48px;
    background: #ffffff;
    border-radius: 16px;
    .title {
      font-size: 24px;
      color: #000000;
    }
  }
}

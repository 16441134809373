.cognitive-asses-main-div {
    .first-button {
        border-radius: 8px;
        max-width: 250px;
        height: 48px;
        font-weight: 800;
        font-size: 16px;
        text-align: center;
        background: rgba(96, 127, 150, 0.08);
        border: 2px solid #607f96;
        border-radius: 8px;
        padding: 12px 32px;
        color: #607f96;
    }

    .second-button {
        max-width: 192px;
        height: 48px;
        font-weight: 800;
        font-size: 16px;
        text-align: center;
        padding: 12px 26px;
        background: #607f96;
        border: 2px solid #607f96;
        border-radius: 8px;
        color: #ffffff;
    }
}


.SchoolAdminDashboardCard {
    .card-small-circle {
        height: 40px;
        width: 40px;
        opacity: 0.1;
        border-radius: 50%;
    }
    .icon-main {
        top: 17%;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        .icon {
            height: 17px;
            width: 17px;
        }
    }
    .counter-number {
        color: #171717;
        font-weight: 800;
        font-size: 16px;
    }
    .counter-card-text {
        font-weight: 400;
        font-size: 12px;
        color: #00000080;
    }
}

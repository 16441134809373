.Not404 {
    font-family: "Avenir Bold";
    font-size: 30px;
    font-weight: 900;
  }
  .not0 {
    color: #607f96;
    font-size: 50px;
  }
  p{
      text-align: center;
  }
  .btn-not{
      display: block;
      margin: auto;
      background-color: #607f96;
      color: white;
      padding: 10px 30px;
      border-radius: 5px;
  }
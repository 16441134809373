.search-box-main-div {
    height: 79px;
    background: #ffff;
    display: grid;
    align-items: center;
    border-radius: 10px 10px 0px 0px !important;

    .search-box {
        background: rgba(96, 127, 150, 0.08);
        border-radius: 8px;
        max-width: 271px;
        height: 40px;

        input {
            border: none;
            background: transparent;
            font-weight: 800;
            &::placeholder {
                color: #d6d6d6 !important ;
            }
        }
    }
    .send-Payment-button {
        background: #ffffff;
        border: 2px solid #607f96;
        border-radius: 8px;
        font-weight: 800;
        font-size: 14px;
        color: #607f96;
        text-align: center;
        padding: 10px 16px;
        height: 40px;
        max-width: 192px;
    }
    select {
        width: 146px;
        height: 40px;
        background: #ffffff;
        border: 2px solid #eeeeee;
        border-radius: 8px;
        padding: 10px 16px;

        option {
            color: #000000;
            font-weight: 800;
            font-size: 14px;
        }
    }
}

.accordion-button{
    background-color: white !important;
    margin-top: -10px !important;
}
.accordion-item-value{
    border: 1px solid rgba(140, 140, 140, 0.155);
    border-radius: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
}
.accordion-body-value{
    border: none;
}
.accordian-email{
    text-align: left;
    padding-top: 2px;
}
.text-start-custom{
    text-align: start !important;
}
.sign-in-main-div {
  margin: 0 !important;
  padding: 0 !important;
  align-items: center !important;
  .welcome-text {
    font-weight: 800;
    font-size: 32px;
    color: #171717;
  }
  .para {
    font-weight: 400;
    font-size: 14px;
    color: #767676;
  }
  .form-main-div {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(160, 163, 189, 0.1);
    border-radius: 10px;
    max-width: 418px;
    margin: auto;

    .form-input {
      font-weight: 500;
      padding: 5px 16px;
      // max-width: 338px;
      height: 44px;
      background: #eff0f6;
      border-radius: 8px;
      border: none !important;
      &::placeholder {
        color: #d6d7e3 !important;
      }
    }
    .form-labels {
      font-weight: 500 !important;
      font-size: 14px;
      color: #171717 !important;
    }
    a {
      font-weight: 500;
      font-size: 14px;
      color: #652581;
      &:hover {
        text-decoration: underline;
      }
    }
    .checkbox-label {
      font-weight: 400;
      font-size: 14px;
      color: #767676;
      margin-right: 12px !important;
    }
    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 4px;
    }
    button {
      border: 2px solid #607f96;
      border-radius: 8px;
      font-family: "Avenir";
      font-weight: 800;
      font-size: 16px;
      text-align: center;
      color: #ffffff !important;
      height: 48px;
      // max-width: 338px;
    }
  }
}

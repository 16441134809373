// custom css added..
.full-height{
  height: 100%;
}

// For arrow navigation buttons
.arrow-container{
  cursor: pointer;
  border-radius: 50%;

  &:active{
      background-color: rgba(58, 57, 57, 0.301);
  }
}

// for Swiper js custom buttons
.next-btn,
.prev-btn{
    color: rgb(97, 88, 88) !important ;
    background-color: rgb(245, 245, 245);
    // height: 38px !important;
    // width: 55px !important;
    border-radius: 12px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.10);
    border: 1px solid rgba(95, 95, 95, 0.171);
    cursor: pointer;
}
.swiper-wrapper{
  // padding-top: 1rem; 
  margin: 1rem 0rem 0rem 0rem
}


.rotate-45 {
  transform: rotate(45deg);
}

// custom scroll bar card
.scroll-card{
  // max-height: 40rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 6px;
    }
    
  &::-webkit-scrollbar-track {
      background: #f1f1f100;
  }

  &::-webkit-scrollbar-thumb {
      background: rgba(184, 184, 184, 0.411);
      border-radius: 0.8rem;
  }

  &::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
}

// for making containers small
.zoomOut{
  zoom: 1;
}

// smooth transitions
.smoothTrans{
  transition: all 0.4s ease;
}


.img-container {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}

/* If you want text inside of the container */
.img-1-1 {
  position: absolute;
  top: 18%;
  left: 0;
  bottom: 0;
  right: 0;
}

.preserve-text-format{
  white-space:pre-wrap
}

.description {
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 5.4em;
  line-height: 1.8em;
}

.AddNewStudent-main-div {
  .add-new-stu-text {
    font-weight: 800;
    font-size: 32px;
    color: #000000;
  }
  .form-div {
    max-width: 871px;
    min-height: 766px;
    background: #ffffff;
    border-radius: 16px;
    padding: 48px;
    margin: auto;
  }
  .verify-btn,
  .verify-btn:hover,
  .verify-btn:focus {
    position: absolute;
    right: 2px;
    top: 34px;
    padding: 9px;
    border: 0;
    font-size: 14px;
    color: var(--noema-grey);
    font-weight: bold;
    background-color: #f8f9fa;
  }
  .first-button {
    font-weight: 800;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.5);
    height: 48px;
    width: 100px;
  }
  .second-button {
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    background: #607f96;
    border-radius: 8px;
    height: 48px;
    width: 100px;
  }
  .toggle-button-group > input[type="radio"] {
    display: none;
  }
  // input[type="date"]::-webkit-calendar-picker-indicator {
  //     display: none;
  //     -webkit-appearance: none;
  // }
}

.edit-parent {
  font-size: 10px;
  background-color: #ffffff96;
  padding: 5px 10px 5px 10px;
  color: black;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.49);
}
.parent-text-name {
  font-size: 15px;
}

// This variable affects the `.h-*` and `.w-*` classes.
$responsive-size: true;
$sizes: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    1:1.5rem,
    5:5rem,
    15: 15%,
    20: 20%,
    25: 25%,
    40: 40%,
    45: 45%,
    'px-50': 50px,
    'px-33': 33px,
    'px-48': 48px,
    50: 50%,
    55: 55%,
    60: 60%,
    75: 75%,
    100: 100%,
    100vh: 100vh,
    85vh: 85vh,
    auto: auto,
    63px : 63px,
    79px : 79px,
    'px-144' : 144px,
    180 : 180px,
    'px-180' : 180px
  ),
  $sizes
);



//-------------------------
//-- Circle size VaLUES
//-------------------------
$circle-size: (
  9 : 9px,
  24: 24px,
  20: 20px,
  36: 36px,
  30: 30px,
  32: 32px,
  40: 40px,
  41: 41px,
  48: 48px,
  54: 54px,
  56: 56px,
  72: 72px,
  79: 79px
);


//-------------------------
//-- Square size VaLUES
//-------------------------
$gr-square-size: (14, 38,52,60, 70,72, 95, 92, 215, 144, 100,);

.vh-85 { height: 50vh }
.topbar-main-div {
  height: 79px;
  background: #ffff;
  display: grid;
  align-items: center;
  position: sticky;
  top: 0;

  .search-box-box {
    background: rgba(96, 127, 150, 0.08);
    border-radius: 8px;
    max-width: 271px;
    height: 40px;

    input {
      border: none;
      color: rgba(0, 0, 0, 0.32);
      background: transparent;
      font-weight: 800;
    }
  }
}
.rounded-div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #eff0f6;
}
.small-circle {
  width: 9px;
  height: 9px;
  right: 10px;
  top: 10px;
  background: #f26464;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
}
.logout-popup-div {
  top: 125%;
  border-radius: 16px;
}

.dashboard-page {
    .dashboard-text {
        font-weight: 800;
        font-size: 24px;
        color: #000000;
    }
    .second-card {
        background: #ffffff;
        border-radius: 10px;
        padding: 23px 19px;

        .title {
            font-weight: 800;
            font-size: 16px;
            color: #171717;
        }
        .num {
            font-weight: 500;
            font-size: 20px;
            color: #000000;
        }
        .text {
            font-weight: 400;
            font-size: 12px;
            color: #171717;
            opacity: 0.5;
        }
        .percent {
            color: #3b9362;
        }
    }
}

//.navbar.navbar-default {
//    background-color: #ffffff;
//    border-color: #ffffff;
//}
//
//
//.navbar.navbar-default .navbar-nav > li > a {
//    color: #292929;
//}
//.navbar.navbar-default .navbar-nav > li > a:hover,
//.navbar.navbar-default .navbar-nav > li > a:focus {
//    color: #ffd4d4;
//}
//.navbar.navbar-default .navbar-nav > .active > a,
//.navbar.navbar-default .navbar-nav > .active > a:hover,
//.navbar.navbar-default .navbar-nav > .active > a:focus {
//    color: #ffd4d4;
//    background-color: #ffffff;
//}
//.navbar.navbar-default .navbar-nav > .open > a,
//.navbar.navbar-default .navbar-nav > .open > a:hover,
//.navbar.navbar-default .navbar-nav > .open > a:focus {
//    color: #ffd4d4;
//    background-color: #ffffff;
//}
//.navbar.navbar-default .navbar-toggle {
//    border-color: #ffffff;
//}
//.navbar.navbar-default .navbar-toggle:hover,
//.navbar.navbar-default .navbar-toggle:focus {
//    background-color: #ffffff;
//}
//.navbar.navbar-default .navbar-toggle .icon-bar {
//    background-color: #292929;
//}
//.navbar.navbar-default .navbar-collapse,
//.navbar.navbar-default .navbar-form {
//    border:none;
//}
//.navbar.navbar-default .navbar-link {
//    color: #292929;
//}
//.navbar.navbar-default .navbar-link:hover {
//    color: #ffd4d4;
//}

.navbar.navbar-default {
    .navbar-nav {
        .nav-link.active::after{
            content: '';
            width: 3rem;
            height: 5px;
            background-color: #607f96;
            //position: absolute;
            top: calc(100% + 5px);
            //left: 0;
            border-radius: 3px 3px 0 0;
        }
    }

}

$theme-colors: ();
$theme-colors: map-merge(
    (
        'primary': $primary,
        'primary-2': $primarydark,
        'secondary': $secondary,
        'noema-orange': $noema-orange,
        'bg-hero': $bg-hero,
        'white': $white,
        'green': $green,
        'blue': $blue,
        'purple': $purple,
        'light-purple': $purple-light,
        'eastern': $eastern-blue,
        'denim': $denim-blue,
        'dodger': $dodger-blue,
        'dodger-2': $dodger-blue-2,
        'spray': $spray-blue,
        'turquoise': $turquoise-blue,
        'regent': $regent-blue,
        'coral': $coral,
        'orange': $orange,
        'orange-2': $outrageous-orange,
        'red': $red,
        'red-2': $red-2,
        'yellow': $yellow,
        'yellow-2': $sun-yellow,
        'casablanca': $casablanca,
        'indigo': $indigo,
        'shamrock': $shamrock,
        'black': $black,
        'black-2': $space-black,
        'gray': $nevada-gray,
        'smoke': $smoke,
        'pink': $pink,
        'violet': $violet,
        'mercury': $mercury,
        'ebony-clay': $ebony-clay,
        // newly added
        'hit-gray': $hit-gray,
        // newly added
        'gallery': $gallery,
        // newly added
        'squeeze': $squeeze,
        // newly added
        'helio': $heliotrope,
        // newly added
        'athens': $athens-gray,
        // newly added
        'concrete': $concrete,
        'allports': $allports,
        'marino': $marino,
        'poppy': $poppy,
        'polar': $polar,
        'egg-blue': $egg-blue,
        'conch': $conch,
        'transBlack': $translucent-black,
        'transBlue': $translucent-blue,
        'transWhite': $translucent-white,
        'bg-green': $bg-green,
        'bg-cream': $bg-cream,
    ),
    $theme-colors
);

//-------------------------
//-- Opacity Values
//-------------------------
$gr-opacity: ();
$gr-opacity: map-merge(
    (
        visible: 1,
        1: 0.1,
        15: 0.15,
        2: 0.2,
        3: 0.3,
        4: 0.4,
        5: 0.5,
        6: 0.6,
        p6: 0.06,
        7: 0.7,
        8: 0.8,
        9: 0.9,
    ),
    $gr-opacity
);

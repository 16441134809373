@font-face {
  font-family: "Avenir Bold";
  src: url("./assets/FONT/Avenir-Next-LT-Pro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir Next";
  src: url("./assets/FONT/Avenir-Next-LT-Pro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Avenir";
  src: url("./assets/FONT/Avenir-Next-LT-Pro-Light.ttf") format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  overflow: hidden;
  scroll-behavior: smooth;
  overflow: hidden !important;
}
body {
  margin: 0;
  font-family: "Avenir", "sans-serif";
  background: #f8f8f8;
}
.avenir-bold {
  font-family: "Avenir Bold", "sans-serif";
}
.avenir-next {
  font-family: "Avenir Next", "sans-serif";
}
.avenir-medium {
  font-family: "Avenir Next Medium", "sans-serif";
}
// SIDEBAR SCROLL
.sidebar {
  background-color: #607f96 !important;
  height: 100vh;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #a6b7c4;
  }
}
// BODY SCROLL
.route {
  overflow-y: scroll;
  height: 100vh;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(166, 183, 196, 0.668)
  }
}
.sc-dmctIk {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.173);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.171);
    background-color: rgba(166, 183, 196, 0.668)
  }
}

button,
button:focus,
input:focus {
  outline: none;
  border: none;
  font-family: "Avenir";
}

button {
  cursor: pointer;
  text-align: center;
}

input[type="date"],
input[type="date"]::placeholder {
  font-family: "Avenir";
  padding-right: 10px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
a,
a:visited {
  text-decoration: none;
  color: inherit;
}
.page-head {
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  font-family: "Avenir Next";
}

.page-counter {
  font-family: "Avenir Next";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #171717;
}
.toast-message {
  background: darkblue;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root,
.css-2gftvx {
  border: none !important;
  padding: 20px !important;
  font-family: "Avenir Next" !important;
  font-weight: 900 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #28293d !important;
  opacity: 0.5 !important;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-2gftvx.Mui-selected {
  border: 2px solid #607f96 !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  padding: 18px 15px 18px 15px !important;
  font-family: "Avenir Next" !important;
  color: #000000 !important;
  opacity: 1 !important;
  font-weight: 900 !important;
  font-size: 12px !important;
  line-height: 14px !important;
}
.loadingLazy {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-btn:hover {
  background-color: #607f96a1 !important;
  color: white;
}
.removexlsxbox {
  display: block;
  justify-content: center;
}
.removexlsxbox img {
  display: block;
  margin: auto;
}
.xlsxremove {
  padding: 0 10px !important;
  margin: 0 !important;
  font-size: 13px !important;
  color: black !important;
  line-height: 25px !important;
  border: 2px solid #607f96ad;
  border-radius: 15px;
  margin-top: 15px !important;
  cursor: pointer;
}
.profile-div-open {
  bottom: 3px;
  left: 0;
  margin-bottom: 20px;
}
.download-assessments{
  font-size: 25px;
  font-weight: 800;
}
.student-profile {
  .student-profile-text {
    font-size: 12px;
    color: #000000;
  }

  .name-text {
    font-weight: 800;
    font-size: 32px;
    color: #000000;
  }

  .delete-button {
    max-width: 100px;
    height: 48px;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 12px 16px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }

  .view-button {
    max-width: 212px;
    height: 48px;
    font-weight: 800;
    font-size: 16px;
    color: #ffffff;
    background: #607f96;
    border-radius: 8px;
  }

  .all-cards-heading {
    font-weight: 800;
    font-size: 24px;
    color: #000000;
  }

  .all-cards-names {
    font-weight: 800;
    font-size: 20px;
    color: #000000;
  }

  .all-cards-email {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }

  .all-texts-first {
    font-size: 20px;
    color: #000000;
  }

  .all-texts-second {
    font-size: 12px;
    opacity: 0.5;
  }

  .all-cards {
    padding: 48px;
    background: #ffffff;
    border-radius: 16px;
    // white-space: nowrap;
  }

  .student-details-card {
    .card-image {
      width: 72px;
      height: 72px;
      border-radius: 8px;
    }
  }

  .reward-points-card {
    border: 1px solid #000000;
    border-radius: 16px;
    padding: 48px !important;

    .slider-container {
      width: 92%;
      margin: auto;
      text-align: center;
    }

    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      background: #fdc607;
      outline: none;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
      border-radius: 2px;
      margin: auto;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 8px;
      height: 8px;
      background: #fdc607;
      box-shadow: 0px 0px 0px 7px rgb(245, 195, 21, 0.4);
      cursor: pointer;
      border-radius: 50%;
    }

    .slider::-moz-range-thumb {
      width: 8px;
      height: 8px;
      background: #fdc607;
      box-shadow: 0px 0px 0px 7px rgb(245, 195, 21, 0.4);
      cursor: pointer;
      border-radius: 50%;
    }

    .all-badges {
      margin: auto;
      width: 99%;
      margin-top: -70px;
      z-index: 100;
      left: -10px;
    }
  }

  .small-box {
    height: 88px;
    // width: 88px;
    border-radius: 8px;
  }
}

.unblock-btn {
  max-width: 212px;
  height: 30px;
  font-weight: 800;
  font-size: 14px;
  color: #ffffff;
  background: #607f96;
  border-radius: 8px;
  padding: 5px 10px 2px 10px !important;
}

.counter-card-div {
  background: #ffffff;
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.1);
  border-radius: 10px;
  height: 92px;
  padding: 16px;
  .card-small-circle {
    height: 60px;
    width: 60px;
    opacity: 0.1;
    border-radius: 50%;
  }
}
.icon-main {
  top: 27%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.counter-number {
  color: #171717;
  font-weight: 800;
  font-size: 20px;
}
.counter-card-text {
  font-weight: 400;
  font-size: 14px;
  color: #00000080;
}

// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }

// Viewport additional helpers

.min-vw-100 { min-width: 100vw !important; }
.min-vw-99_5 { min-width: 99.5vw !important; }
.min-vw-99 { min-width: 99vw !important; }
.min-vw-98 { width: 98vw !important; }
.min-vw-97 { width: 97vw !important; }
.min-vw-90 { width: 90vw !important; }
.min-vw-80 { min-width: 80vw !important; }
.min-vw-60 { min-width: 60vw !important; }
.min-vw-40 { min-width: 40vw !important; }

.min-vh-100 { min-height: 100vh !important; }
.min-vh-80  { min-height: 80vh !important; }
.min-vh-60  { min-height: 60vh !important; }
.min-vh-40  { min-height: 40vh !important; }

.vw-100 { width: 100vw !important; }
.vw-99 { width: 99vw !important; }
.vw-98 { width: 98vw !important; }
.vw-97 { width: 97vw !important; }
.vw-90 { width: 90vw !important; }
.vw-75 { width: 75vw !important; }
.vw-50 { width: 50vw !important; }
.vw-25 { width: 25vw !important; }
.vw-15 { width: 15vw !important; }
.vw-10 { width: 10vw !important; }
.vw-5 { width: 5vw !important; }

.vh-100 { height: 100vh !important; }
.vh-50 { height: 50vh !important; }
.vh-55 { height: 55vh !important; }


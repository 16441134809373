.SchoolSubAdmins-main-div {
    .SchoolSubAdmins-text {
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        color: #000000;
    }
    .first-button {
        font-weight: 800;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        background: #607f96;
        border-radius: 8px;
        padding: 14px 41px;
    }
}

.modal-title-text {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;
}

.deleteModalBack {
    background-color: rgba(0, 0, 0, 0.126) !important;
}
.delete-circle-div {
    border: 4px solid #4c6577;
    height: 100px;
    width: 100px;
    display: grid;
    justify-content: center;
    align-items: center;
}

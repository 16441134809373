$white: #fff;
$black: #000;

$green: #00b074;
$violet: #9c5fff;
$purple: #800080;
$blue: #4743db;
$indigo: #5877be;
$denim-blue: #1a5aeb;
$dodger-blue: #336ef7;
$dodger-blue-2: #4d83ff;
$eastern-blue: #2397af;
$spray-blue: #65efe3;
$turquoise-blue: #50d7e5;
$shamrock: #25ce95;
$coral: #fa8557;
$outrageous-orange: #fa6032;
$orange: #fa5f1c;
$red: #f71e3f;
$red-2: #fc3f3f;
$yellow: #ffb300;
$sun-yellow: #facf32;
$casablanca: #f8c546;
$squeeze: #edf8f5;
$athens-gray: #f4f5f8;
$concrete: #f2f2f2;
$space-black: #2b3940;
$nevada-gray: #6b6e6f;
$smoke: #7e8989;
$allports: #0070ac;
$poppy: #be392b;
$marino: #4064ad;
$polar: #f3f9fc;
$egg-blue: #02bfd5;
$conch: #d2dcd6;

$accent: #dd792c;

$pink: #fc4980;
$regent-blue: #b0d5e8;
$mercury: #e5e5e5;
$ebony-clay: #1d292e; // newly added
$hit-gray: #adb4b7; // newly added
$gallery: #f0f0f0; // newly added
$heliotrope: #9c5fff; // newly added
$noema-grey: #607f96;
$noema-orange: #d27d2e; // newly added
$noema-landing-blue: #004e6d;
$purple-light: #c761c7;
$bg-hero: #607f961a;    
$bg-green: #7EBE6B1A;    
$bg-cream: #fdcab22a;    
$translucent-black: rgba(0, 0, 0, 0.068);
$translucent-blue: #1876f20d;
$translucent-white: #fcfcfcbe;

$primary: $noema-grey;
$secondary: $nevada-gray;
$primarydark: $noema-landing-blue;

$headings-color: var(--color-headings);


.bg-gradient-1{
    background-image: linear-gradient(to right, rgb(4, 134, 91) 50%, #8dbb78 100%);
  }
.bg-gradient-2{
    background-image: linear-gradient(to right, #004e6d 0%, #197da0 100%, #004e6d 0%);
}

.bg-gradient-3{
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), linear-gradient(0deg, rgba(255, 255, 255, 0.22), rgba(255, 255, 255, 0.22))
}
.students-main-div {
  .first-button {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    max-width: 197px;
    height: 48px;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 12px 32px;
  }
  .second-button {
    max-width: 159px;
    height: 48px;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    padding: 12px 26px;
    background: #607f96;
    border: 2px solid #607f96;
    border-radius: 8px;
    color: #ffffff;
  }
}

.custom-click {
  cursor: pointer;
}

.sidebar-main-div {
  // width: 253px !important;
  // transition: width 0.3s;
  position: sticky;
  top: 0;
}
.sidebar-main-div-small {
  // width: 64px !important;
  // transition: width 0.3s;

  position: sticky;
  top: 0;
}

// ---------------------logo----------------------
.logo-div {
  height: 72px;
}
.logo-size-one {
  height: 20.07px;
  width: 40.55px;
  transition: width 0.1s;
}
.logo-size-two {
  height: 51.5px;
  width: 127.33px;
  transition: width 0.1s;
}
// ----------toogle-button and small-line---------
.toogle-button-main-div {
  position: absolute;
}
.toogle-button {
  border: none;
  background-color: transparent;
  padding: 0 10px;
}
.small-line {
  opacity: 0.1;
  border: 1px solid #ffffff;
}
// -------------Navigation and icons------------
.link-and-icon {
  a {
    display: inline-block;
  }
}
.nav-text {
  color: #ffffff;
}
.sidebar_text_active {
  font-weight: 800;
}
.sidebar_text_inactive {
  opacity: 0.5;
  font-size: 14px;
  line-height: 19px;
  padding-top: 5px;
}
.menu-logo{
  width: 19px;
  height: 20px;
}

// --------------profile in bottom-------------
@mixin profileDivCommonCss($top: 90%, $width: 207px) {
  position: sticky;
  top: $top;
  display: grid;
  align-items: center;
  padding: 12px;
  height: 68px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: $width;
}
.profile-div-open {
  @include profileDivCommonCss;
}
.profile-div-close {
  @include profileDivCommonCss($top: 100%, $width: 64px);
}
.profile-img-div {
  width: 40px;
  height: 40px;
  background: #c4c4c4;
  border-radius: 100px;
  flex: grid;
  align-items: center;
}
